<!--
 * @Author: lzh
 * @Date: 2022-12-15 18:33:04
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 16:16:16
 * @Description: file content
-->
<template>
  <div
    class="delivery-system-image-text-box px-delivery-system-image-text-box"
    :style="`
      padding-top: ${configs.paddingTop}px;
    padding-right: ${configs.paddingRight}px;
    padding-bottom: ${configs.paddingBottom}px;
    padding-left: ${configs.paddingLeft}px;
  `"
    @click="clickItem('bgImage')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      :class="`edit_${Xindex}_title`"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      :class="`edit_${Xindex}_desc`"
    ></h2>
    <div
      class="question"
      :style="`background-color:${configs.backgroundColor};`"
    >
      <div class="child">
        <div
          v-html="$util.rex.getHtml(configs[`${nowL}text`] || configs.text)"
          style="margin: 0 6px"
        ></div>
      </div>
      <div
        class="grandson1"
        :style="`background-color:${configs.backgroundColor};`"
      ></div>
      <div
        class="grandson2"
        :style="`background-color:${configs.backgroundColor};`"
      ></div>
      <div class="square"></div>
      <div
        class="item"
        v-for="item,index in configs.items"
        :key="index"
        :class="`item_${index}`"
      >
        <el-image
          class="icon"
          :src="item.icon"
        ></el-image>
        <div
          class="title"
          v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
        ></div>
      </div>
    </div>
    <div
      class="square_"
      :style="`background-color:${configs.backgroundColor};`"
    >
      <div
        class="_child"
        :style="`border-top-color:${configs.backgroundColor};`"
      ></div>
    </div>

    <!-- <div class="bottom-box"></div> -->
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "self-deliverySystemImageText",
  mixins: [langMixin],
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {}
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;

  },
  data () {
    return {
      isEdit: false
    }
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },

    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  }
}
</script>

<style lang="less" scoped>
.delivery-system-image-text-box {
  background: #fafafa;
  padding: 70px 0 286px !important;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
    text-align: center;
    padding-bottom: 80px;
  }
  .question {
    position: relative;
    width: 514px;
    height: 514px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0;
    .child {
      width: 250px;
      height: 250px;
      background-color: #fafafa;
      z-index: 5;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .grandson1,
    .grandson2 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 132px;
      height: 132px;
      margin-top: -66px;
      z-index: 3;
      background-color: #ccc;
      border-radius: 50%;
    }
    .grandson2 {
      left: 50%;
      bottom: 0;
      margin-top: auto;
      margin-left: -66px;
    }
    .square {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 257px;
      height: 257px;
      background: #fafafa;
      z-index: 2;
    }
    .item {
      font-size: 26px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      background: #6a7070;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      z-index: 10;
      display: flex;
      .title {
        margin-left: 8px;
        white-space: nowrap;
        font-size: 26px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
      }
      .icon {
        width: 40px;
        img {
          width: 100%;
        }
      }
    }
    .item_0 {
      position: absolute;
      top: 150px;
      right: 390px;
    }
    .item_1 {
      position: absolute;
      top: -20px;
      left: 150px;
    }
    .item_2 {
      position: absolute;
      top: 250px;
      left: 390px;
    }
  }
  .square_ {
    position: relative;
    left: -2px;
    width: 128px;
    height: calc(64px + 30px);
    background: #ccc;
    z-index: 5;
    margin: -64px auto;
    ._child {
      position: absolute;
      top: 94px;
      left: -64px;
      width: 0;
      height: 0;
      border: 128px solid #ccc;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
  .bottom-box {
    padding-bottom: 100px;
  }
}

@media screen and(max-width:1000px) {
  .px-delivery-system-image-text-box {
    // padding: 30px 70px 110px !important;
    .title {
      font-size: 16px !important;
    }
    .desc {
      font-size: 12px !important;
    }
    .item {
      .title {
        line-height: 18px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .delivery-system-image-text-box {
    .child {
      font-size: 60px !important;
    }
  }
  .px-delivery-system-image-text-box {
    padding: 25px 0 88px 0 !important;
    .question {
      position: relative;
      width: 160px;
      height: 160px;
      margin: 23px auto 0;
      .child {
        width: 80px;
        height: 80px;
        background-color: #fafafa;
        z-index: 5;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        // font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        // line-height: 21px;
      }
      .grandson1,
      .grandson2 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        z-index: 3;
        border-radius: 50%;
      }
      .grandson2 {
        left: 50%;
        bottom: 0;
        margin-top: auto;
        margin-left: -20px;
      }
      .square {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 80px;
        z-index: 2;
        .item {
        }
      }
      .item {
        background: #6a7070;
        display: flex;
        align-items: center;
        padding: 4px 7px;
        z-index: 10;
        display: flex;
        .title {
          margin-left: 3px;
          white-space: nowrap;
          font-size: 11px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        .icon {
          width: 18px;
          img {
            width: 100%;
          }
        }
      }
      .item_0 {
        position: absolute;
        top: 40px;
        right: 130px;
      }
      .item_1 {
        position: absolute;
        top: -20px;
        left: 40px;
      }
      .item_2 {
        position: absolute;
        top: 90px;
        left: 124px;
      }
    }
    .square_ {
      position: relative;
      width: 40px;
      height: 40px;
      background: #ccc;
      z-index: 5;
      margin: -20px auto;
      left: 0;
      ._child {
        position: absolute;
        top: 40px;
        left: -20px;
        width: 0;
        height: 0;
        border: 40px solid #ccc;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }
  }
}
</style>